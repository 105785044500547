import Rest from "../Rest";
import IApiResponse from "../../interfaces/api/IApiResponse";
import config from "../clients-configuration/AppServiceConfig";
import {AxiosError} from "axios";

class JoinUsService extends Rest<IApiResponse>{
    public async sendPetition(data: object): Promise<IApiResponse | AxiosError> {
        try {
            const res = await this.http.post<IApiResponse>(this.entityName, data);
            return res.data;
        } catch (e) {
            return e as AxiosError;
        }
    }
}

export default new JoinUsService("join-us", config);