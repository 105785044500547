import {gql} from "apollo-boost";

export const GET_LEVELS_WITH_AS_BY_SUBJECT = gql`
    query GetLevelsWithASBySubject($subject_id: String!){
        levels(whereHas: [academic_subjects]) {
            id,
            name,
            abbr,
            grades {
                id,
                name,
                abbr
                academic_subjects (subject_id: $subject_id) {
                    id,
                    subject_id,
                    units {
                        videos {
                            id,
                        }
                    }
                }
            }
        }
    }
`;