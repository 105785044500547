import Service from "../Service";
import config from "../clients-configuration/YoutubeServiceConfig";
import {AxiosError, AxiosResponse} from "axios";
import IYoutubeVideo from "../../interfaces/youtube/IYoutubeVideo";
import IYoutubeList from "../../interfaces/youtube/IYoutubeList";

class YoutubeService extends Service<IYoutubeVideo | IYoutubeList> {
    constructor() {
        super("videos", config);

        this.http.interceptors.request.use(instanceConfig => {
            instanceConfig.params = Object.assign(instanceConfig.params, {}, {
                ...config.params,
            });

            return instanceConfig;
        })
    }

    async get(id: string): Promise<IYoutubeList | AxiosError> {
        try {
            const res: AxiosResponse<IYoutubeList> = await this.http.get<IYoutubeList>(this.entityName, {
                params: {
                    id,
                    part: 'id,snippet',
                },
            });

            return res.data;
        } catch (ex) {
            return ex as AxiosError;
        }
    }
}

export default new YoutubeService();