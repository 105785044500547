import React, {useState} from "react";
import {Button, Form, Input, Layout, notification} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {Rule} from "antd/es/form";
import backLine from "../assets/home/back-line-vector.png";
import backDots from "../assets/home/home-asset-3.png";
import img from "../assets/join-us/img.png";
import 'react-phone-input-2/lib/style.css'
import JoinUsService from "../api/services/JoinUsService";
import helpers from "../helpers/functions";
import {AxiosError} from "axios";
import {useForm} from "antd/es/form/Form";
import IApiResponse from "../interfaces/api/IApiResponse";
// @ts-ignore
import PhoneInput from 'react-phone-input-2';
import "../styles/views/JoinUs.less"

const validationRules: { email: Array<Rule>, phone: Array<Rule> } = {
    email: [
        {required: true, message: "Debes ingresar tu correo electrónico"},
        {type: "email", message: "Debes ingresar un correo electrónico válido"}
    ],
    phone: [
        {
            validator: (_, value: string) => {
                const regex = /^(\+\d+ )/;
                const matches = regex.exec(value);
                if (matches !== null && matches[1] !== undefined) {
                    const _v = value.substring(regex.lastIndex, value.length - 1);

                    if (_v.trim().length > 0) {
                        return Promise.resolve();
                    } else {
                        return Promise.reject("Debes ingresar un número de teléfono válido");
                    }
                } else {
                    return Promise.reject("Debes ingresar un número de teléfono válido");
                }
            }
        },
    ]
};

interface FormValues {
    email: string,
    phone: string,
}

const JoinUs: React.FC = () => {
    const [frmJoinUs] = useForm();
    const [isLoading, setIsLoading] = useState(false);

    const sendMail = async (data: FormValues) => {
        setIsLoading(true);
        const res = await JoinUsService.sendPetition(data);
        setIsLoading(false);

        if (helpers.isAxiosError(res)) {
            const err = res as AxiosError;
            helpers.axiosErrorHandling(err, {
                422: (response) => helpers.setFormErrorByResponse(frmJoinUs, response),
                default: (error) => notification.open({
                    message: "Error",
                    description: error.response?.data.message,
                    type: "error",
                    placement: "bottomLeft"
                }),
            })
        } else {
            const resData = res as IApiResponse;
            if (resData.status) {
                notification.open({
                    message: "La operación ha sido efectuada éxitosamente",
                    description: resData.message,
                    type: "success",
                    placement: "bottomLeft"
                });

                frmJoinUs.resetFields();
            } else {
                notification.open({
                    message: "Error",
                    description: resData.message,
                    type: "error",
                    placement: "bottomLeft"
                });
            }
        }
    };

    const onSubmit = (values: any) => {
        const data = values as FormValues;
        sendMail(data).then();
    };

    return (
        <Layout id="join-us-component" className="component-layout">
            <div className="info">
                <h1>¿Eres docente y quieres compartir tus clases?</h1>
                <p>Déjanos tu correo y te contactaremos con las instrucciones para poder subir tu contenido a la
                    plataforma.</p>
                <p>Gracias por ayudarnos a llevar la educación a todos los que lo necesitan.</p>

                <Form
                    name="frmJoinUs"
                    initialValues={{email: '', phone: ''}}
                    onFinish={onSubmit}
                    layout="vertical"
                    form={frmJoinUs}
                >
                    <Form.Item name="email" label="Correo electrónico" rules={validationRules.email}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name="phone" label="Número de teléfono" rules={validationRules.phone}>
                        <PhoneInput country={"sv"}/>
                    </Form.Item>
                    <Form.Item className="submitBtn-item">
                        <Button type="primary" htmlType="submit" size="large" loading={isLoading}>
                            Enviar datos <FontAwesomeIcon style={{marginLeft: "10px"}} icon={faPaperPlane}/>
                        </Button>
                    </Form.Item>
                </Form>
            </div>  

            <div className="img">
                <img src={img} alt="join-us"/>
            </div>
            <div className="back back-line">
                <img src={backLine} alt="join-us-back-line"/>
            </div>
            <div className="back back-dots">
                <img src={backDots} alt="join-us-back-dots"/>
            </div>
        </Layout>
    );
};

export default JoinUs;