import React, {useEffect, useState} from 'react'
import Router from "./routes/Router";
import {Spin} from "antd";

const App: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);

    const onLoadListener = () => {
        setIsLoading(false)
    };

    useEffect(() => {
        document.addEventListener("DOMContentLoaded", onLoadListener);
        return () => {
            document.removeEventListener("DOMContentLoaded", onLoadListener);
        }
    });

    return <Spin spinning={isLoading}>
        <Router/>
    </Spin>;
};

export default App;
