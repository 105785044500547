import {AxiosError} from "axios";
import IHelpers from "../interfaces/helpers/IHelpers";

const allowedCodes: Array<string> = ["400", "401", "403", "404", "422", "500"];

const helpers: IHelpers = {
    isAxiosError: (object: any) => {
        const err = object as AxiosError;

        const isServerError = err.isAxiosError !== undefined && err.isAxiosError;
        const isClientError = (err.code !== undefined && err.code !== "200") || (err.response?.status !== undefined && err.response?.status !== 200);

        return (isServerError || isClientError);
    },
    GoTo: (history, path) => {
        history.push(path);
    },
    GoBack: (history) => {
        history.goBack();
    },
    axiosErrorHandling: (error, callbacks) => {
        /***
         * TODO:
         * - Tipificar de manera adecuada este método
         * - Parametrizar una estructura de error a manejar (Como cuando se regresan objetos que implementan la interfaz IApiResponse)
         */
        if (error.code !== undefined && error.code === "500") {
            if (typeof callbacks["500"] === "function") {
                callbacks["500"](error);
            } else if (typeof callbacks.default === "function") {
                callbacks.default(error);
            }
        } else {
            const status: string | undefined = error.response?.status.toString();
            // @ts-ignore
            if (status !== undefined && allowedCodes.indexOf(status) !== -1 && typeof callbacks[status] === "function") {
                // @ts-ignore
                callbacks[status](error.response);
            } else if (typeof callbacks.default === "function") {
                callbacks.default(error);
            }
        }
    },
    setFormErrorByResponse: (form, response) => {
        const data = form.getFieldsValue();
        Object.entries(data).forEach(entry => {
            const [key, value] = entry;
            if (data.hasOwnProperty(key)) {
                const errors = response?.data.errors.hasOwnProperty(key) ? response?.data.errors[key] : [];
                form.setFields([{
                    name: key,
                    value,
                    errors
                }]);
            }
        });
    },
    graphqlErrorHandling: (error) => {
        if (error !== undefined) {
            error.graphQLErrors.forEach(e => {
                console.log(e);
            });
        }
        // return (
        //     <p>{error.message}</p>
        // )
    }
};

export default helpers;