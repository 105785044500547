import React, {useEffect, useState} from "react";
import {RouteComponentProps} from "react-router-dom";
import IVideo from "../interfaces/models/IVideo";
import helpers from "../helpers/functions";
import ISubject from "../interfaces/models/ISubject";
import IGrade from "../interfaces/models/IGrade";
import {Alert, Button, Col, Layout, Row} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import YoutubeService from "../api/services/YoutubeService";
import IYoutubeList from "../interfaces/youtube/IYoutubeList";
import IYoutubeVideo from "../interfaces/youtube/IYoutubeVideo";
import back from "../assets/video-detail/video-back.png";
import "../styles/views/VideoDetail.less";
import {useLazyQuery} from "@apollo/react-hooks";
import {videos as VideosQueries} from "../api/queries";

interface RouteProps {
    video_id: string,
}

const VideoDetail: React.FC<RouteComponentProps<RouteProps>> = ({match, history}) => {
    const video_id = match.params.video_id;
    const [video, setVideo] = useState<IVideo | null>(null);
    const [videoInfo, setVideoInfo] = useState<IYoutubeVideo | null>(null);
    const [subject, setSubject] = useState<ISubject | null>(null);
    const [grade, setGrade] = useState<IGrade | null>(null);
    const [videoNotFound, setVideoNotFound] = useState(false);

    const [getVideo, {loading, data, error}] = useLazyQuery<{ video: IVideo }>(VideosQueries.GET_VIDEO);

    /**
     * TODO: Implementar handler de excepciones Axios
     */
    async function getYoutubeVideo(id: string) {
        const res = await YoutubeService.get(id);

        if (helpers.isAxiosError(res)) {
        } else {
            const list = res as IYoutubeList;
            setVideoInfo(list.items[0] as IYoutubeVideo);
        }
    }

    useEffect(() => {
        getVideo({
            variables: {
                id: video_id,
            }
        })
    }, [video_id, getVideo]);

    useEffect(() => {
        if (!loading && data?.video !== undefined && error === undefined) {
            setVideo(data.video);
        } else if (error !== undefined) {
            //TODO: Handle error
            setVideoNotFound(true);
        }
    }, [loading, data, error]);


    useEffect(() => {
        if (video !== null) {
            getYoutubeVideo(video.id).then();

            if (video.unit?.academic_subject?.subject !== undefined) {
                setSubject(video.unit?.academic_subject?.subject);
            }

            if (video.unit?.academic_subject?.grade !== undefined) {
                setGrade(video.unit?.academic_subject?.grade)
            }
        }
    }, [video]);

    return (
        <Layout id="show-video-component" className="component-layout">
            <Row className="component-content">
                {videoNotFound && (
                    <Col>
                        <Button
                            onClick={() => helpers.GoBack(history)}
                            type="primary"
                        >
                            <FontAwesomeIcon icon={faArrowLeft}/> Atrás</Button>
                        <Alert
                            message="Error"
                            description="El vídeo que buscas no existe."
                            type="error"
                            showIcon
                        />
                    </Col>
                )}

                {!videoNotFound && video !== null &&
                <>
                    <Col span={24}>
                        <Button
                            onClick={() => helpers.GoBack(history)}
                            className="return-btn"
                            type="link"
                        >
                            <FontAwesomeIcon
                                icon={faArrowLeft}
                            />
                            Atrás
                        </Button>
                    </Col>
                    <Col span={24} className="info-cont">
                        <p className="subject-name">{subject?.name}</p>
                        <span className="breadcrumb-custom">
                            <p
                                className="level-name"
                                onClick={() => helpers.GoTo(history, `/classes/subject/${subject?.id}`)}
                            >
                                {grade?.level?.name}
                            </p>
                            <span>/</span>
                            <p
                                className="grade-name"
                                onClick={() => helpers.GoTo(history, `/videos/${video?.unit?.academic_subject_id}`)}
                            >
                                {grade?.name}
                            </p>
                            <span>/</span>
                            <p className="unit-name">{video?.unit?.name}</p>
                        </span>
                        <p className="video-title">{videoInfo?.snippet.title}</p>

                        <div className="embed-cont">
                            <iframe
                                title="Embed video" id="yt-player"
                                src={`${process.env.REACT_APP_EMBED_YOUTUBE_URL}/${video?.id}`}
                                frameBorder="0"
                            />
                        </div>
                    </Col>
                </>
                }
            </Row>
            <div className="back">
                <img src={back} alt="back"/>
            </div>
        </Layout>
    );
};

export default VideoDetail;