import gql from "graphql-tag";

export const GET_ACADEMIC_SUBJECT = gql`
    query GetAcademicSubject($id: String!){
        academic_subject(id: $id) {
            id,
            grade_id,
            subject_id,
            grade {
                id,
                name,
                abbr,
                level {
                    id,
                    name,
                },
            },
            subject {
                name,
                color,
            },
        }
    }
`;