import React, {useEffect, useState} from "react";
import {Button, Col, Drawer, Layout, Menu, Row} from "antd";
import logo from "../../assets/icon.png";
import HomeRoutes from "../../routes/HomeRoutes";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBook, faChalkboardTeacher, faInfo, faBars, faTimes} from "@fortawesome/free-solid-svg-icons";
import {RouteComponentProps} from "react-router-dom";
import sidenavBackLine from "../../assets/layouts/public/sidenav-back-line.png";
import "./Public.less"

const {Header, Content, Footer} = Layout;

const menuOptions: Array<{ text: string, path: string, icon: JSX.Element }> = [
    {
        text: "Clases",
        path: "/#subjects",
        icon: <FontAwesomeIcon icon={faBook}/>
    },
    {
        text: "Docentes",
        path: "/join-us",
        icon: <FontAwesomeIcon icon={faChalkboardTeacher}/>
    },
    {
        text: "Acerca de nosotros",
        path: "/about-us",
        icon: <FontAwesomeIcon icon={faInfo}/>
    },
];

const Public: any = ({history, location}: RouteComponentProps) => {
    const [drawer, setDrawer] = useState(false);
    const [selectedKey, setSelectedKey] = useState("home");

    function OnDrawerClose(): void {
        setDrawer(false);
    }

    function GoTo(path: string, key: string): void {
        const e: HTMLElement | null = document.getElementById("public-app-layout");
        e?.scrollIntoView({block: "start", behavior: "smooth"});

        if (drawer) {
            setDrawer(false);
        }

        history.push(path);
    }

    useEffect(() => {
        setSelectedKey(`${location.pathname}${location.hash}`);
    }, [location]);

    return (
        <div>
            <Drawer
                closable={false}
                className="main-sidenav"
                title={(
                    <div className="sidenav-logo-title">
                        <img className="header-logo" src={logo} alt="ESE Logo"/>
                        <Button onClick={OnDrawerClose} type="link"><FontAwesomeIcon icon={faTimes}/></Button>
                    </div>
                )}
                placement="left"
                onClose={OnDrawerClose}
                visible={drawer}
                width="100vw"
                height="100vh"
            >
                <div>
                    <Menu selectedKeys={[selectedKey]}>
                        {menuOptions.map(o =>
                            <Menu.Item
                                key={o.path}
                                onClick={() => GoTo(o.path, o.path)}>
                                <span>{o.text}</span>
                            </Menu.Item>
                        )}
                    </Menu>
                    <div className="back">
                        <img src={sidenavBackLine} alt="Sidenav back line"/>
                        <div className="circle"/>
                    </div>
                </div>
            </Drawer>

            <Layout id="public-app-layout">
                <Header id="home-header">
                    <div className="header-logo">
                        <div className="logo-cont" onClick={() => GoTo("/", "home")}>
                            <img src={logo} alt="Logo"/>
                            <span className="hide-sm text">ESE virtual</span>
                        </div>
                        <Button
                            className="show-sm"
                            type="link"
                            onClick={() => setDrawer(true)}
                            id="btnShowSidenav"
                        >
                            <FontAwesomeIcon size="1x" icon={faBars}/>
                        </Button>
                    </div>

                    <Menu
                        mode="horizontal"
                        className="hide-sm"
                        selectedKeys={[selectedKey]}
                    >
                        {menuOptions.map(o => (
                            <Menu.Item key={o.path} onClick={() => GoTo(o.path, o.path)}>{o.text}</Menu.Item>
                        ))}
                    </Menu>
                </Header>

                <div className="ribbon">
                    Beta
                </div>

                <Content id="main-app-container">
                    <HomeRoutes/>
                </Content>
            </Layout>

            <Footer id="home-footer">
                <Row>
                    <Col className="footer-logo-col" md={6} sm={24}>
                        <div className="logo-cont" onClick={() => GoTo("/", "home")}>
                            <img src={logo} alt="Logo"/>
                            <span className="text">ESE virtual</span>
                        </div>
                        <p>
                            Derechos reservados © 2020 ESE ediciones
                        </p>
                    </Col>
                    <Col className="show-sm" md={18} sm={24}>
                        <Menu selectedKeys={[selectedKey]}>
                            {menuOptions.map(o =>
                                <Menu.Item
                                    key={o.path}
                                    onClick={() => GoTo(o.path, o.path)}>
                                    <span>{o.text}</span>
                                </Menu.Item>
                            )}
                        </Menu>
                    </Col>
                </Row>
            </Footer>
        </div>
    );
};

export default Public;