import React from "react";
import {Route, Switch} from "react-router-dom";
import JoinUs from "../views/JoinUs";
import AboutUs from "../views/AboutUs";
import Home from "../views/Home";
import Classes from "../views/Classes";
import VideoList from "../views/VideoList";
import VideoDetail from "../views/VideoDetail";

const HomeRoutes : React.FC = () => {
    return (
        <Switch>
            <Route exact component={Home} path='/'/>
            <Route exact component={JoinUs} path='/join-us'/>
            <Route exact component={AboutUs} path='/about-us'/>
            <Route exact component={Classes} path='/classes/subject/:subject_id/'/>
            <Route exact component={VideoList} path='/videos/:academic_subject_id'/>
            <Route exact component={VideoDetail} path='/video/:video_id'/>
        </Switch>
    );
};

export default HomeRoutes;