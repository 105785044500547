import React, {useLayoutEffect} from "react";
import ISubject from "../interfaces/models/ISubject";
import {RouteComponentProps} from "react-router-dom";
import helpers from "../helpers/functions";
import {Button, Col, Layout, Row} from "antd";
import laptop from "../assets/home/home-laptop.png";
import smLaptop from "../assets/home/sm-home-laptop.png";
import section1BackImage from "../assets/home/home-back-1.png";
import section1BackLine from "../assets/home/back-line-vector.png";
import section2Image from "../assets/home/home-asset-2.png";
import section2Back from "../assets/home/home-asset-3.png";
import section3Group1 from "../assets/home/section-3-group-1.png";
import section3Group2 from "../assets/home/section-3-group-2.png";
import section3Group3 from "../assets/home/section-3-group-3.png";
import "../styles/views/Home.less";
import SubjectsPanel from "../components/Public/Home/SubjectsPanel";

const Home: React.FC<RouteComponentProps> = ({history, location}: RouteComponentProps) => {
    const ScrollToSubjects = () => {
        const e: HTMLElement | null = document.getElementById("subjects");
        e?.scrollIntoView({behavior: "smooth", block: "center"})
    };

    useLayoutEffect(() => {
        if (location.hash === "#subjects") {
            document.addEventListener("DOMContentLoaded", ScrollToSubjects);

            if (document.readyState === 'complete') {
                ScrollToSubjects();
            }
        }
    }, [location]);

    return (
        <Layout id="home-component" className="component-layout">
            <Row>
                <Col md={24} id="section-1">
                    <div className="back">
                        <img src={section1BackLine} alt="Section 1 back line"/>
                    </div>

                    <div className="section-1-text">
                        <div className="info">
                            <div className="info-aux-cont">
                                <p>Estás listo?</p>
                                <p>Continuemos aprendiendo</p>
                                <p>
                                    Durante esta emergencia creamos una plataforma para que docentes de El Salvador
                                    puedan
                                    enseñar a las nuevas generaciones utilizando herramientas de fácil acceso.
                                </p>
                                <Button onClick={() => helpers.GoTo(history, "/#subjects")}>Ver clases</Button>
                            </div>
                        </div>

                        <div className="back">
                            <img src={section1BackImage} alt="section 1 back"/>
                        </div>
                    </div>

                    <div className="section-1-image">
                        <img className="cutted" src={laptop} alt="Laptop"/>
                        <img className="completed" src={smLaptop} alt="Laptop"/>
                        <span>Explora y aprende</span>
                    </div>
                </Col>
                <Col md={24} id="section-2">
                    <div className="back hide-land hide-sm">
                        <img src={section2Back} alt="section 2 back"/>
                    </div>
                    <div className="image-cont">
                        <div className="square _1"/>
                        <div className="square _2"/>
                        <div className="image">
                            <img src={section2Image} alt="section 2"/>
                        </div>
                    </div>
                    <div className="info-cont">
                        <h1>Nos comprometemos con la educación</h1>
                        <p>
                            Profesores de todo el salvador se unen para educar de forma gratuita a los estudiantes que
                            no pueden ir a las aulas durante la emergencia del nuevo coronavirus.
                        </p>

                        <Button onClick={() => helpers.GoTo(history, '/about-us')}>Conoce más</Button>
                    </div>
                </Col>
                <Col md={24} id="section-3">
                    <h1>Aprende a tu ritmo</h1>
                    <p>
                        Te damos acceso a clases para que continues tu proceso de aprendizaje desde casa durante el
                        estado de emergencia.
                    </p>

                    <Row>
                        <Col md={24} className="groups-cont">
                            <div className="group-info">
                                <img src={section3Group1} alt="Group 1"/>
                                <p>Todo el contenido es totalmente gratis</p>
                            </div>
                            <div className="group-info">
                                <img src={section3Group2} alt="Group 2"/>
                                <p>Míralo cuantas veces quieras</p>
                            </div>
                            <div className="group-info">
                                <img src={section3Group3} alt="Group 3"/>
                                <p>Aprende de diferentes profesores</p>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col md={24} id="section-4">
                    <h1>Elige la materia que deseas estudiar</h1>
                    <SubjectsPanel onClick={(s: ISubject) => helpers.GoTo(history, `/classes/subject/${s.id}`)}/>
                </Col>
            </Row>
        </Layout>
    );
};

export default Home;