import React from "react";
import {BrowserRouter, Route} from "react-router-dom";
import "../views/layouts/Public.less";
import PublicLayout from "../views/layouts/Public";

const Router: React.FC = () => {
    return (
        <div>
            <BrowserRouter>
                <Route path="/" component={PublicLayout} />
            </BrowserRouter>
        </div>
    );
};

export default Router;