import { gql } from "apollo-boost";

export const GET_SUBJECTS = gql`
    {
        subjects {
            id,
            name,
            color,
            image,
        }
    }
`;

export const GET_SUBJECT_BY_ID = gql`
    query GetSubject($id: String!, $getAcademicSubjects: Boolean = false) {
        subject(id: $id) {
            id,
            name,
            color,
            image,
            academic_subjects @include (if: $getAcademicSubjects) {
                id,
                grade {
                    level_id,
                    name, 
                    abbr
                }
            }
        }
    }
`;