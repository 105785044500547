import {AxiosRequestConfig} from "axios";

const config: AxiosRequestConfig = {
    baseURL: `https://www.googleapis.com/youtube/v3`,
    params: {
        "key": "AIzaSyB_wcJhwl4OqTuTnqZylNJLFl3I6tQyvFM",
    },
    headers: {
        "Accept": "application/json",
    },
};

export default config;