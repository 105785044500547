import React, {useEffect, useState} from "react";
import {RouteComponentProps} from "react-router-dom";
import ISubject from "../interfaces/models/ISubject";
import ILevel from "../interfaces/models/ILevel";
import IAcademicSubject from "../interfaces/models/IAcademicSubject";
import {Alert, Badge, Button, Col, Layout, Row, Spin} from "antd";
import helpers from "../helpers/functions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import back from "../assets/classes/back.png";
import "../styles/views/Classes.less";
import IVideo from "../interfaces/models/IVideo";
import {useLazyQuery} from '@apollo/react-hooks';
import {levels as levelsQueries, subjects as subjectsQueries} from "../api/queries";

interface RouteParams {
    subject_id: string
}

const Classes: React.FC<RouteComponentProps<RouteParams>> = ({match, history}: RouteComponentProps<RouteParams>) => {
    const subject_id = match.params.subject_id;

    const [subject, setSubject] = useState<ISubject | null>(null);
    const [levels, setLevels] = useState<Array<ILevel> | null>(null);

    const [getSubject, {loading: subjectLoading, data: subjectData, error}] = useLazyQuery<{ subject: ISubject | null }>(subjectsQueries.GET_SUBJECT_BY_ID);

    const [getLevels, {data: levelsData, loading: levelsLoading, error: levelsError}] = useLazyQuery<{ levels: Array<ILevel> }>(levelsQueries.GET_LEVELS_WITH_AS_BY_SUBJECT);

    const getVideoCount: (as: IAcademicSubject | undefined) => number = (as: IAcademicSubject | undefined) => {
        if (as === undefined) return 0;

        const aux: Array<IVideo> = [];
        as?.units?.forEach(u => {
            if (u.videos !== undefined) {
                aux.push(...u.videos);
            }
        });

        return aux.length;
    };

    useEffect(() => {
        if (subject_id !== undefined && subject_id !== null) {
            getSubject({
                variables: {
                    id: subject_id,
                }
            });
        }
    }, [subject_id, getSubject]);

    useEffect(() => {
        if (!subjectLoading && subjectData?.subject !== undefined && subjectData.subject !== null && error === undefined) {
            const s: ISubject = subjectData.subject;
            setSubject(s);

            getLevels({
                variables: {
                    subject_id: s.id
                }
            });
        } else if (error !== undefined) {
            //TODO: Handle error
        }
    }, [subjectLoading, subjectData, error, getLevels]);

    useEffect(() => {
        if (!levelsLoading && levelsError === undefined && levelsData !== undefined) {
            const levels: Array<ILevel> = levelsData.levels;
            setLevels(levels);
        } else if (levelsError !== undefined) {
            //TODO: HandleError
        }
    }, [levelsLoading, levelsData, levelsError]);

    return (
        <Spin spinning={subjectLoading} size="large" tip="Cargando...">
            <Layout id="classes-component" className="component-layout">
                {error && (
                    <div>
                        <Button
                            onClick={() => helpers.GoTo(history, "/")} className="return-btn"
                            type="link"><FontAwesomeIcon
                            icon={faArrowLeft}
                        />
                            Atrás
                        </Button>

                        <Alert
                            message="Error al cargar la materia seleccionada"
                            description={
                                <>
                                    <p>Ocurrió un error al intentar obtener los datos, inténtalo más tarde...</p>
                                    <p>${error?.message}</p>
                                </>
                            }
                            type="error"
                            showIcon
                        />
                    </div>
                )}

                {!error && !subjectLoading && (
                    <div>
                        <div className="back">
                            <img src={back} alt="back"/>
                        </div>
                        <Row align="middle" className="subject-cont" style={{background: subject?.color}}>
                            <div className="icon-back">
                                <img src={subject?.image} alt="Subject icon back"/>
                            </div>
                            <Col md={24}>
                                <Button
                                    onClick={() => helpers.GoTo(history, "/")}
                                    className="return-btn"
                                    type="link"
                                >
                                    <FontAwesomeIcon icon={faArrowLeft}/> Atrás
                                </Button>
                            </Col>
                            <Col md={24}>
                                <h1>{subject?.name}</h1>

                                {levelsLoading && (
                                    <Row style={{display: "flex", justifyContent: "center"}}>
                                        <Spin spinning={true} size="large"/>
                                    </Row>
                                )}

                                {!subjectLoading && levels?.map(l => (
                                    <Row key={l.id} className="level-cont">
                                        <Col md={24} lg={24} xl={24} sm={24}>
                                            <h3>{l.name}</h3>
                                        </Col>
                                        <Col span={24}>
                                            <Row gutter={[10, 10]}>
                                                {l.grades?.map(g => {
                                                    const grade_academic_subjects = g?.academic_subjects?.filter(as => as.subject_id === subject?.id);
                                                    const as = !(grade_academic_subjects !== undefined && grade_academic_subjects.length > 0) ? undefined : grade_academic_subjects[0];
                                                    return (
                                                        <Col key={g.id} md={12} sm={24} lg={8} xs={24}>
                                                            <Badge
                                                                count={getVideoCount(as)}
                                                                title="Cantidad de vídeos">
                                                                <Button
                                                                    onClick={() => helpers.GoTo(history, `/videos/${as?.id}`)}
                                                                    type="default"
                                                                    block
                                                                    size="large"
                                                                >
                                                                    {g.name}
                                                                </Button>
                                                            </Badge>
                                                        </Col>
                                                    );
                                                })}
                                            </Row>
                                        </Col>
                                    </Row>
                                ))}

                                {!levelsLoading && levelsError !== undefined && (
                                    <Alert
                                        message="Error al cargar los datos de las clases"
                                        description={
                                            <>
                                                <p>Ocurrió un error al intentar obtener los datos, inténtalo más
                                                    tarde...</p>
                                                <p>${levelsError.message}</p>
                                            </>
                                        }
                                        type="error"
                                        showIcon
                                    />
                                )}
                            </Col>
                        </Row>

                    </div>
                )}
            </Layout>
        </Spin>
    );
};

export default Classes;