import IVideo from "../../../interfaces/models/IVideo";
import {Col} from "antd";
import playIcon from "../../../assets/video-list/play.png";
import React from "react";

interface Props {
    video: IVideo,
    onClick: (video: IVideo) => void,
    color: string | undefined
}

const VideoDisplay: React.FC<Props> = ({video, onClick, color}) => (
    <Col key={video.id} xxl={6} xl={6} lg={6} md={8} sm={12} xs={24} className="video-cont">
        <div className="video-cont-aux">
            <div className="thumbnail-cont" onClick={() => onClick(video)}>
                <img src={video.thumbnail} alt={video.id}/>
                <div className="back-play-icon-cont">
                    <img src={playIcon} alt="Play icon"/>
                </div>
            </div>
            <div className="video-info-cont" style={{backgroundColor: color}}>
                <p className="video-teacher-name">{video.teacher?.name} {video.teacher?.surname}</p>
                <p className="video-unit-name">{video.unit?.name}</p>
                <p title={video.description} className="video-description">{video.description}</p>
            </div>
        </div>
    </Col>
);

export default VideoDisplay;