import Rest from "./Rest";
import {AxiosError} from "axios";
import IApiResponse from "../interfaces/api/IApiResponse";

export default class Service<T> extends Rest<T>{
    public async fetch(): Promise<Array<T> | AxiosError> {
        return await super.fetch();
    }

    public async get(id: string): Promise<T | AxiosError> {
       return await super.get(id);
    }

    public async store(entity: T): Promise<IApiResponse<T> | AxiosError> {
        return await super.store(entity);
    }

    public async search(params: object): Promise<Array<T> | AxiosError> {
        return await super.search(params);
    }
}