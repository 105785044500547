import getAxiosInstance from './axios';
import {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import IApiResponse from "../interfaces/api/IApiResponse";

/**
 * TODO
 * - Estructurar la clase para que únicamente sirva para obtener un cliente HTTP.
 * - Eliminar todos los métodos y añadirlos a clase Service.
 * */
export default abstract class Rest<T> {
    protected entityName: string;
    protected http: AxiosInstance;

    constructor(entity: string, config: AxiosRequestConfig) {
        this.entityName = entity;
        this.http = getAxiosInstance(config);
    }

    public async fetch(): Promise<Array<T> | AxiosError> {
        try {
            const res: AxiosResponse<Array<T>> = await this.http.get<Array<T>>(this.entityName);
            return res.data;
        } catch (ex) {
            return ex as AxiosError;
        }
    }

    public async get(id: string): Promise<T | AxiosError> {
        try {
            const res: AxiosResponse<T> = await this.http.get<T>(`${this.entityName}/${id}`);
            return res.data;
        } catch (ex) {
            return ex as AxiosError;
        }
    }

    public async store(entity: T): Promise<IApiResponse<T> | AxiosError> {
        try {
            const res: AxiosResponse<IApiResponse<T>> = await this.http.post<IApiResponse<T>>(`${this.entityName}/`, entity);
            return res.data;
        } catch (ex) {
            return ex as AxiosError;
        }
    }

    public async update(id: string, entity: T): Promise<IApiResponse<T> | AxiosError> {
        try {
            const res: AxiosResponse<IApiResponse<T>> = await this.http.put<IApiResponse<T>>(`${this.entityName}/${id}`, entity);
            return res.data;
        } catch (ex) {
            return ex as AxiosError;
        }
    }

    public async delete(id: string): Promise<IApiResponse<T> | AxiosError> {
        try {
            /**
             * TODO: Tipificar función delete
             * */
            const res: AxiosResponse<IApiResponse<T>> = await this.http.delete(`${this.entityName}/${id}`);
            return res.data;
        } catch (ex) {
            return ex as AxiosError;
        }
    }

    public async search(params: any): Promise<Array<T> | AxiosError> {
        try {
            const res: AxiosResponse<Array<T>> = await this.http.post<Array<T>>(`${this.entityName}/search`, params);
            return res.data;
        } catch (ex) {
            return ex as AxiosError;
        }
    }
}