import React, {useEffect, useState} from "react";
import {RouteComponentProps} from "react-router-dom";
import IAcademicSubject from "../interfaces/models/IAcademicSubject";
import IVideo from "../interfaces/models/IVideo";
import helpers from "../helpers/functions";
import {Alert, Button, Col, Layout, Row, Spin} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import back from "../assets/video-list/back.png";
import "../styles/views/VideoList.less";
import {academic_subjects as AcademicSubjectsQueries, videos as VideosQueries} from "../api/queries";
import {useLazyQuery} from "@apollo/react-hooks";
import VideoDisplay from "../components/Public/VideoList/VideoDisplay";

interface RouteParams {
    academic_subject_id: string
}

interface IViewEntities {
    academicSubject: boolean,
    videos: boolean,
}

const VideoList: React.FC<RouteComponentProps<RouteParams>> = ({match, history}) => {
    const academic_subject_id = match.params.academic_subject_id;
    const [academicSubject, setAcademicSubject] = useState<IAcademicSubject | null>(null);
    const [videos, setVideos] = useState<Array<IVideo> | null>(null);

    const [getAcademicSubject, {loading: isLoadingAs, data: asData, error: asError}] = useLazyQuery<{ academic_subject: IAcademicSubject | undefined }>(AcademicSubjectsQueries.GET_ACADEMIC_SUBJECT);

    const [getVideos, {loading: isLoadingVideos, data: videosData, error: videosError}] = useLazyQuery<{ videos: Array<IVideo> }>(VideosQueries.GET_VIDEOS_BY_ACADEMIC_SUBJECT);

    useEffect(() => {
        getAcademicSubject({
            variables: {
                id: academic_subject_id,
            }
        });
    }, [academic_subject_id, getAcademicSubject]);

    useEffect(() => {
        if (!isLoadingAs && asData?.academic_subject !== undefined && asData.academic_subject !== null && asError === undefined) {
            const as: IAcademicSubject = asData.academic_subject;
            setAcademicSubject(as);

            getVideos({
                variables: {
                    academic_subject_id: as.id,
                },
            });
        } else if (asError !== undefined) {
            //TODO: Handle error
        }
    }, [isLoadingAs, asData, asError, getVideos]);

    useEffect(() => {
        if (!isLoadingVideos && videosData?.videos !== undefined && videosError === undefined) {
            setVideos(videosData.videos);
        } else if (videosError !== undefined) {
            //TODO: Handle error
        }
    }, [isLoadingVideos, videosData, videosError]);

    return (
        <Spin spinning={isLoadingAs} size="large" tip="Cargando...">
            <Layout id="video-list-component" className="component-layout">
                {!isLoadingAs && asError && (
                    <div>
                        <Button
                            onClick={() => helpers.GoBack(history)}
                            className="return-btn"
                            type="link"><FontAwesomeIcon
                            icon={faArrowLeft}
                        />
                            Atrás
                        </Button>
                        <Alert
                            message="Error al cargar la información seleccionada"
                            description={
                                <>
                                    <p>Ocurrió un error al intentar obtener los datos, inténtalo más tarde...</p>
                                    <p>${asError.message}</p>
                                </>
                            }
                            type="error"
                            showIcon
                        />
                    </div>
                )}
                {!isLoadingAs && !asError && (
                    <>
                        <div className="back">
                            <img src={back} alt="back"/>
                        </div>
                        < Row className="info-container">
                            <Col md={24}>
                                <Button
                                    onClick={() => helpers.GoBack(history)}
                                    className="return-btn"
                                    type="link"><FontAwesomeIcon
                                    icon={faArrowLeft}
                                /> Atrás</Button>
                            </Col>
                            <Col md={24}>
                                <p className="subject-name">
                                    {academicSubject?.subject?.name}
                                </p>
                                <span className="breadcrumb-custom">
                                    <p
                                        className="level-name"
                                        onClick={() => helpers.GoTo(history, `/classes/subject/${academicSubject?.subject_id}`)}
                                    >
                                        {academicSubject?.grade?.level?.name}
                                    </p>
                                    <span>/</span>
                                    <p className="grade-name">
                                        {academicSubject?.grade?.name}
                                    </p>
                                </span>
                            </Col>
                            <Col md={24}>
                                <Row gutter={[10, 20]} className="video-cont-row">
                                    {isLoadingVideos && (
                                        <Col span={24} style={{display: "flex", justifyContent: "center"}}>
                                            <Spin spinning={true} size="large"/>
                                        </Col>
                                    )}
                                    {!isLoadingVideos && videosError && (
                                        <Col span={24}>
                                            <Alert
                                                message="Error al cargar los vídeos"
                                                description={
                                                    <>
                                                        <p>Ocurrió un error al intentar obtener los vídeos, inténtalo
                                                            más tarde...</p>
                                                        <p>${videosError?.message}</p>
                                                    </>
                                                }
                                                type="error"
                                                showIcon
                                            />
                                        </Col>
                                    )}
                                    {!isLoadingVideos && !videosError && videos?.length === 0 && (
                                        <Col span={24}>
                                            <Alert
                                                message="No se encontraron resultados"
                                                description="No existen vídeos bajo los criterios seleccionados..."
                                                type="error"
                                                showIcon
                                            />
                                        </Col>
                                    )}
                                    {!isLoadingVideos && !videosError && videos?.map(v =>
                                        <VideoDisplay
                                            key={v.id}
                                            video={v}
                                            onClick={(video) => helpers.GoTo(history, `/video/${video.id}`)}
                                            color={academicSubject?.subject?.color}
                                        />
                                    )}
                                </Row>
                            </Col>
                        </Row>
                    </>
                )}
            </Layout>
        </Spin>
    );
};

export default VideoList;