import {gql} from "apollo-boost";

export const GET_VIDEOS_BY_ACADEMIC_SUBJECT = gql`
    query GetVideosByAcademicSubject($academic_subject_id: String!) {
        videos(academic_subject_id: $academic_subject_id)
        {
            id,
            description,
            thumbnail,
            teacher {
                name,
                surname,
            },
            unit {
                name,
            }
        }
    }
`;

export const GET_VIDEO = gql`
    query GetVideo($id: String!) {
        video(id: $id) {
            id,
            description,
            unit {
                id,
                name,
                academic_subject_id,
                academic_subject {
                    grade {
                        id,
                        name,
                        level {
                            id,
                            name,
                        }
                    },
                    subject {
                        id,
                        name,
                    }
                }
            }
        }  
    }
`;