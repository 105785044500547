import React from "react";
import {Button, Layout} from "antd";
import image from "../assets/about-us/img.png";
import back from "../assets/home/home-asset-3.png";
import helpers from "../helpers/functions";
import {RouteComponentProps} from "react-router"
import "../styles/views/AboutUs.less";

const AboutUs: React.FC<RouteComponentProps> = ({history}) => {
    return (
        <Layout id="about-us-component" className="component-layout">
            <div className="info">
                <section>
                    <h1>¿Qué es ESE Virtual?</h1>
                    <p>
                        ESE Virtual es una plataforma que recopila clases de docentes de todas partes de El Salvador
                        para el fácil acceso de sus alumnos, alumnos de otras instituciones u otros que estén
                        interesados en el aprendizaje.
                    </p>
                </section>

                <section>
                    <h1>¿Por qué lo hacemos?</h1>
                    <p>
                        Nos hemos comprometido a facilitar la educación a todo aquel que la necesite y darles
                        herramientas actuales a los docentes para educar a una nueva generación que actualmente no puede
                        estar en las aulas por la alerta del nuevo Coronavirus.
                    </p>
                </section>

                <section>
                    <Button onClick={() => helpers.GoTo(history, '/join-us')} id="btnContactUs">Contáctanos</Button>
                </section>
            </div>

            <div className="img">
                <img src={image} alt="about-us"/>
                <div className="circle"/>
            </div>

            <div className="back">
                <img src={back} alt="about-us-back"/>
            </div>

        </Layout>
    );
};

export default AboutUs;