import {Alert, Spin} from "antd";
import React from "react";
import {useQuery} from '@apollo/react-hooks';
import ISubject from "../../../interfaces/models/ISubject";
import {subjects as subjectsQueries} from "../../../api/queries";

interface Props {
    onClick: (s: ISubject) => void
}

const SubjectsPanel: React.FC<Props> = ({onClick}) => {
    const {loading: isLoading, data, error} = useQuery<{ subjects: Array<ISubject> }>(subjectsQueries.GET_SUBJECTS);

    const subjects = data?.subjects;

    return (
        <div>
            {error === undefined && (
                <Spin spinning={isLoading} size="large" tip="Cargando...">
                    <div className="subjects-cont" id="subjects">
                        {!isLoading && subjects?.map(s =>
                            <div
                                onClick={() => onClick(s)}
                                className="subject-card"
                                key={s.id}
                                style={{background: s.color}}
                            >
                                <div className="back">
                                    <img src={s.image} alt={`${s.abbr}_back`}/>
                                </div>
                                <div className="data">
                                    <img className="subject-logo" src={s.image} alt={s.abbr}/>
                                    <span>{s.name}</span>
                                </div>
                            </div>
                        )}
                        <div className="circle"/>
                    </div>
                </Spin>
            )}

            {!isLoading && error !== undefined && subjects === undefined && (
                <Alert
                    message="Error al cargar las materias"
                    description={(
                        <>
                            <p>Ocurrió un error al intentar obtener las materias, inténtalo más tarde...</p>
                            <p>{error.message}</p>
                        </>
                    )}
                    type="error"
                    showIcon
                />
            )}
        </div>
    )
};

export default SubjectsPanel;